import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import notfound from "../images/notfound.png"
import { Row, Container } from "react-bootstrap"

const NA = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="notFound py-5">
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "400px",
          }}
        >
          <Row>
            <div className="col-lg-4 offset-lg-1">
              <img src={notfound} className="w-100" />
            </div>
            <div
              className="col-lg-6"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div>
                <h1 className="jtf-head">404: Not Found</h1>
                <p>The page you requested could not be found.</p>
              </div>
            </div>
          </Row>
        </div>
      </Container>
    </div>
  </Layout>
)

export default NA
